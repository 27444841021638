<template>
  <div class="introduction">
    <div class="introduction_bg">
      <img :src="introduction_bg" width="100%" />
      <div></div>
    </div>
    <div class="introduction_about">
      <div class="introduction_about_name">浙江中峪农业发展有限责任公司</div>
      <div class="introduction_about_content">
        浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业
        乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。
      </div>
      <div class="introduction_about_detail"></div>
    </div>
    <div class="introduction_qualification">
      <div class="introduction_qualification_img" v-for="item in list" :key="item.id">
        <div class="introduction_qualification_img_left">
          <img :src="item.medicinalImage" />
        </div>
        <div>
          <div class="introduction_qualification_img_left_name">
            道地药材-{{ item.medicinalName }}
          </div>
          <div class="introduction_qualification_img_left_tips">
            {{ item.medicinalDescribe }}
          </div>
          <div class="introduction_qualification_img_left_type">
            种类
          </div>
          <div class="introduction_qualification_img_left_typeName">
            {{ item.medicinalTypeDescribe }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "introduction",
  data() {
    return {
      introduction_bg: require('../../static/image/introduction_bg.png'),
      list: []
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
    this.getList()
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        const obj = {
            title: '中峪农业企业介绍', // 分享标题
            desc: '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/5697ed02fb52470eb3289bd68931ebd3.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    getList() {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/introduce/list`
      ).then(async (res) => {
        this.list = res.data.data.list
      });
    },
  },
};
</script>

<style scoped lang="scss">
.introduction_bg {
  background: #f7f7f7;

  img {
    width: 100%;
  }
}

.introduction_about {
  width: 100%;
  padding: 50px 0 0 0;
  background: url("../../static/image/introduction_bg1.png") no-repeat;
  background-size: cover;

  .introduction_about_bg {
    width: 1280px;
    margin: 0 auto;
    text-align: center;

    .introduction_about_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }

    .introduction_about_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }

    .introduction_about_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 50px auto;
    }
  }

  .introduction_about_img {
    display: flex;
    margin: 0 auto;
    justify-content: center;

    img {
      width: 880px;
      height: 440px;
    }
  }

  .introduction_about_name {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #354a32;
    line-height: 36px;
    letter-spacing: 1px;
    margin: 40px 0 25px 0;
    font-family: 黑体;
  }

  .introduction_about_content {
    width: 1200px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    color: #354a32;
    line-height: 35px;
    letter-spacing: 2px;
    font-family: 宋体;
    text-align: center;
  }

  .introduction_about_detail {
    cursor: pointer;
    padding-bottom: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    background: linear-gradient(180deg, #8eb368 0%, #608640 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.introduction_culture {
  width: 100%;
  padding: 100px 0;

  .introduction_culture_bg {
    width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;

    .introduction_culture_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }

    .introduction_culture_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }

    .introduction_culture_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 50px auto;
    }
  }

  .introduction_culture_img {
    display: flex;
    margin: 0 auto;
    width: 1280px;
    justify-content: space-between;

    img {
      width: calc((100% - 36px) / 3);
    }
  }

  .introduction_culture_content {
    width: 1280px;
    margin: 90px auto;
    font-size: 20px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
  }

  .introduction_culture_list {
    display: flex;
    width: 1280px;
    margin: 90px auto;
    justify-content: space-between;

    .introduction_culture_list_item {
      width: 200px;
      height: 200px;
      text-align: center;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.04);
      position: relative;

      .introduction_culture_list_item_name {
        margin-top: 55px;
      }

      .introduction_culture_list_item_value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 黑体;
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 1px;
        text-align: left;
      }
    }

    .introduction_culture_list_itemRed {
      background-color: #9e1307;
    }
  }
}

.introduction_qualification {
  width: 100%;
  padding: 50px 0;
  background: #f7f7f7;

  .introduction_qualification_bg {
    width: 1280px;
    margin: 0 auto;
    text-align: center;

    .introduction_qualification_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }

    .introduction_qualification_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }

    .introduction_qualification_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 90px auto;
    }
  }

  .introduction_qualification_img {
    display: flex;
    margin: 0 auto;
    width: 1200px;
    justify-content: center;
    flex-wrap: wrap;

    .introduction_qualification_img_left {
      display: flex;
    }

    .introduction_qualification_img_left_name {
      text-align: center;
      margin: 18px 0;
      width: 180px;
      height: 40px;
      background: #6b766a;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      font-family: 宋体;
    }

    .introduction_qualification_img_left_tips {
      font-size: 14px;
      font-weight: 400;
      color: #858585;
      line-height: 23px;
      letter-spacing: 1px;
    }

    .introduction_qualification_img_left_type {
      margin: 18px 0;
      text-align: center;
      width: 64px;
      height: 40px;
      background: #6b766a;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      font-family: 宋体;
    }

    .introduction_qualification_img_left_typeName {
      font-size: 14px;
      font-weight: 400;
      color: #858585;
      line-height: 23px;
    }

    .introduction_qualification_img_left_detail {
      cursor: pointer;
      text-align: right;
      margin-top: 40px;
      font-family: 黑体;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      background: linear-gradient(180deg, #8eb368 0%, #608640 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      width: 92%;
      height: 294px;
    }

    div {
      width: 480px;
    }
  }

  .introduction_qualification_phone {
    margin-top: 74px;
    font-size: 16px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 22px;
    display: flex;
    justify-content: center;

    .introduction_qualification_phone_call {
      margin-right: 26px;
    }
  }

  .introduction_qualification_content {
    width: 1280px;
    margin: 90px auto;
    font-size: 20px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
  }
}
</style>
